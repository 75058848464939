import React from 'react'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'

import Layout from '../components/layout'

const NotFoundPage = ({ data: { site } }) => (
  <Layout>
    <Helmet>
      <title>{site.siteMetadata.title}</title>
      <meta name="description" content={site.siteMetadata.description} />
    </Helmet>

    <div id="main">
      <section id="one">
        <header className="major">
          <h2>404. Not Found</h2>
        </header>
        <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
        <p>
          If you are looking for an old blog post, my dev blog has moved to{' '}
          <a href="https://duncanbrown.dev">duncanbrown.dev</a>
        </p>
      </section>
    </div>
  </Layout>
)

export default NotFoundPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`
